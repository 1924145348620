<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..." loosing-text="Lepaskan untuk refresh..."  success-text="Refresh Berhasil" @refresh="onRefresh">
    <div class="loan-status">
      <BaseHeaderBar backType="2" :showCustomerService="true" />
      <div v-if="orderStatus === 1 || orderStatus === 2 || orderStatus === 10" class="loan-status1">
        <div class="desc">
          <p class="intro">Dalam proses peninjauan</p>
        </div>
      </div>

      <div v-else-if="orderStatus === 3" class="loan-status3">
        <div class="desc">
          <p class="intro">Dalam proses pencairan</p>
          <div class="info">
            <p>Jumlah yang diterima</p>
            <p>Rp{{ loanInfo.ardrrivalAmount }}</p>
            <p>Nama bank penerima</p>
            <p>{{ loanInfo.ardrrivalBank }}</p>
            <p>Nomor rekening bank penerima</p>
            <p>{{ loanInfo.ardrrivalBankcard }}</p>
          </div>
        </div>
      </div>

      <div v-else-if="orderStatus === 4" class="loan-status4">
        <div class="desc">
          <p class="intro">Anda tidak dapat melakukan pinjaman saat ini</p>
          <p class="detail">Untuk saat ini Anda tidak memenuhi persyaratan. Pastikan untuk selalu mempertahankan riwayat kredit yang baik.</p>
        </div>
      </div>

      <div v-else-if="orderStatus === 5" class="loan-status5">
        <div class="repayment-content">
          <p class="p1">Menunggu pembayaran</p>
          <p class="p2">Harap melakukan pembayaran sebelum tanggal {{ loanInfo.bidrllDetails.ardrrivalTime }}</p>
          <div class="overdue">Sudah terlambat {{ loanInfo.bidrllDetails.ovdrerdueDay }} hari</div>
          <div class="repayment-sort">
            <div>
              <p>Pelunasan Total</p>
              <p>Rp{{ loanInfo.bidrllDetails.fudrllrepaymentAmount }}</p>
            </div>
            <div>
              <p>Perpanjangan Durasi</p>
              <p>Rp{{ loanInfo.bidrllDetails.exdrhibitionrepaymentaAount }}</p>
            </div>
          </div>
        </div>
        <button class="detail" @click="goRepaymenDetail">Rincian</button>
        <button type="button" class="full-repayment-btn" @click="goRepay(1)">Pelunasan</button>
        <button type="button" class="installment-repayment-btn" @click="goRepay(2)">Perpanjangan</button>
      </div>

      <div v-else-if="orderStatus === 0 || orderStatus === 6 || orderStatus === 7" class="loan-status6">
        <p class="loan-title">Jumlah pinjaman</p>
        <div class="loan-amount">Rp{{ loanAmount }}</div>
        <div class="loan-amount-sort">
          <div v-for="(item, index) in loanInfo.mudrltipleLoanList" :key="index" :class="{ active: activeIndex === index }" @click="selectLoan(item, index)">
            Rp{{ item.loanMoney }}
          </div>
        </div>
        <div :class="{ 'info-block': hasRate }">
          <div v-if="loanTimers && loanTimers.length">
            <p :class="{ 'deadline-title': !hasRate }">Lama Pinjaman</p>
            <p :class="{ 'time': !hasRate }">{{ loanTimers[0] }} Hari</p>
          </div>
          <div v-if="hasRate">
            <p>Suku bunga tahunan</p>
            <p>{{ loanInfo.dadryRate }}</p>
          </div>
        </div>
        <button type="button" class="submit" @click="submit">Langsung Pengajuan</button>

        <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay class="no-loan-dialog">
          <div class="no-loan-dialog-content">
            <p class="rise-limit-title">Saya ingin menaikkan limit</p>
            <h3 class="tip-title">Bagaimana cara menaikkan limit pinjaman</h3>
            <p class="tip">Mempertahankan kebiasaan baik dan selalu melunasi tepat waktu. Anda bisa mendapatkan kenaikan limit apabila menghindari perilaku keterlambatan pembayaran.</p>
          </div>
          <button type="button" class="confirm-btn" @click="onCloseDialog()">Mengerti</button>
        </van-dialog>
      </div>

      <div v-else-if="orderStatus === 8" class="loan-status8">
        <div class="desc">
          <p class="intro">Verifikasi KTP gagal</p>
          <p class="detail">Foto KTP yang Anda unggah tidak jelas, sehingga tidak dapat lolos evaluasi. Silakan unggah ulang.</p>
        </div>
      <button type="button" class="modify-btn" @click="goAuthentication">Edit data</button>
      </div>

      <div v-else-if="orderStatus === 9" class="loan-status9">
        <div class="desc">
          <p class="intro">Pencairan gagal</p>
          <p class="detail">Pencairan tidak dapat dilakukan karena data rekening bank Anda tidak tersedia. Silakan isi ulang.</p>
        </div>
        <button type="button" class="modify-btn" @click="goBankInfo">Edit data</button>
        <van-dialog v-model="cancelLoanVisible" :showConfirmButton="false" close-on-click-overlay class="cancel-loan-dialog">
          <div class="cancel-loan-dialog-content">
            <h3 class="cancel-loan-dialog-title">Berhasil dibatalkan</h3>
            <p class="tip">Pinjaman Anda telah dibatalkan. Anda tidak perlu verifikasi data lagi jika ingin melakukan pinjaman.</p>
          </div>
          <button type="button" class="confirm-btn" @click="onCloseDialog('cancelLoanVisible')">Mengerti</button>
        </van-dialog>
      </div>

      <div v-else-if="orderStatus === 12" class="loan-status12">
        <p class="loan-title">Jumlah pinjaman</p>
        <div class="loan-amount">Rp{{ loanInfo.lodranAmount }}</div>
        <template v-if="loanInfo.avdrailablePeriod && loanInfo.avdrailablePeriod.length">
          <p class="deadline-title">Lama Pinjaman</p>
          <p class="time">{{ loanInfo.avdrailablePeriod[0] }} Hari</p>
        </template>
        <button type="button" class="withdraw-btn" @click="withdraw">Segera Tarik</button>
      </div>

      <van-dialog v-model="feeVisible" :showConfirmButton="false" close-on-click-overlay>
        <template #title>
          <p class="fee-title">Rincian Biaya</p>
        </template>
        <div class="fee-detail">
          <div>
            <p>Jumlah Pinjaman</p>
            <p>Rp{{ feeDetail.lodranCash }}</p>
          </div>
          <div>
            <p>Jumlah hari pinjaman</p>
            <p>{{ feeDetail.lodranTimes }} Hari</p>
          </div>
          <div>
            <p>Biaya Administrasi</p>
            <p>Rp{{ feeDetail.todrtalCost }}</p>
          </div>
          <div>
            <p>Jumlah yang diterima</p>
            <p>Rp{{ feeDetail.ardrrivalCash }}</p>
          </div>
        </div>
        <div class="footer-btn">
          <button type="button" class="cancel-btn" @click="onCloseDialog('feeVisible')">Batal</button>
          <button type="button" class="ok-btn" @click="onOkFee">Yakin</button>
        </div>
      </van-dialog>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, PullRefresh } from 'vant'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'

Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Home',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      isLoading: false,
      cancelLoanVisible: false,
      feeVisible: false,
      orderStatus: '',
      loanInfo: {},
      feeDetail: {},
      activeIndex: -1,
      loanTimers: [],
      loanAmount: '',
      hasRate: false
    }
  },
  created () {
    this.getOrderStatus()
  },
  methods: {
    getOrderStatus (isRefresh) {
      request.post('homeDRInterface')
        .then(res => {
          this.orderStatus = res.ordrderStatus
          this.loanInfo = res
          this.hasRate = res.ordrderStatus === 0 || (res.ordrderStatus === 7 && res.redr_loan === 0)
          if (res.ordrderStatus === 0 || res.ordrderStatus === 6 || res.ordrderStatus === 7) {
            this.loanAmount = res.lodranMaxAmount
            let index = -1
            res.mudrltipleLoanList.forEach((item, i) => {
              if (item.loanStatus) {
                index = i
              }
            })
            if (index > -1) {
              this.activeIndex = index
              this.selectedLoanItem = res.mudrltipleLoanList[index]
              this.loanAmount = this.selectedLoanItem.loanMoney
              this.loanTimers = this.initLoanTimers = this.selectedLoanItem.loanTimers || []
            }
          }
          isRefresh && (this.isLoading = false)
        })
    },
    getFeeDetail (item, loanType) {
      const postData = {
        lodranCash: item.loanMoney,
        lodranTimers: item.loanTimers[0],
        lodranType: loanType
      }
      this.withdrawType = loanType
      request.post('indrterestrateBomb', postData)
        .then(res => {
          this.feeDetail = res
        })
    },
    goRepaymenDetail () {
      this.$router.push('/repaymenDetail')
    },
    goBankInfo () {
      if (this.loanInfo.badrnkStatus) {
        const postData = {
          apdrplicationAmount: this.loanInfo.lodranAmount,
          prdromotionChannel: 'AppStore'
        }
        request.post('sudrbmitloanOrder', postData)
          .then(() => {
            this.getOrderStatus()
          })
      } else {
        this.goAuthentication()
      }
    },
    goRepay (repayType) {
      const { ordrderID, padrymentID, fudrllrepaymentAmount, exdrhibitionrepaymentaAount } = this.loanInfo.bidrllDetails
      this.$router.push({
        path: '/repay',
        query: {
          repayType,
          amount: repayType === 1 ? fudrllrepaymentAmount : exdrhibitionrepaymentaAount,
          orderId: ordrderID,
          payID: padrymentID
        }
      })
    },
    selectLoan (item, index) {
      const loanStatus = item.loanStatus
      if (loanStatus) {
        if (index === this.activeIndex) {
          this.activeIndex = -1
          this.selectedLoanItem = null
          this.loanAmount = this.loanInfo.lodranMaxAmount
          this.loanTimers = this.initLoanTimers
        } else {
          this.activeIndex = index
          this.selectedLoanItem = item
          this.loanAmount = item.loanMoney
          this.loanTimers = item.loanTimers
        }
      } else {
        this.visible = true
      }
    },
    submit () {
      if (this.selectedLoanItem) {
        if (this.orderStatus === 0 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 0)) {
          this.$router.push({
            path: '/authentication',
            query: {
              amount: this.selectedLoanItem.loanMoney
            }
          })
        } else if (this.orderStatus === 6 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 1)) {
          this.getFeeDetail(this.selectedLoanItem, 2)
          this.feeVisible = true
        }
      } else {
        Toast('Silahkan pilih pinjaman terlebih dahulu')
      }
    },
    onOkFee () {
      if (this.flag) return
      this.flag = true
      const { lodranCash, lodranTimes } = this.feeDetail
      const postData = {
        lodranAmount: lodranCash,
        lodranPeriod: lodranTimes,
        widrthdrawType: this.withdrawType
      }
      request.post('widrthdrawCash', postData)
        .then(() => {
          this.feeVisible = false
          this.orderStatus = 1
        })
        .finally(() => {
          this.flag = false
        })
    },
    goAuthentication () {
      this.$router.push({
        path: '/authentication',
        query: {
          amount: this.loanInfo.lodranAmount
        }
      })
    },
    withdraw () {
      const postData = {
        loanMoney: this.loanInfo.lodranAmount,
        loanTimers: this.loanInfo.avdrailablePeriod
      }
      this.getFeeDetail(postData, 1)
      this.feeVisible = true
    },
    onRefresh () {
      this.getOrderStatus(true)
    }
  }
}
</script>

<style scoped lang="less">
.desc {
  width: 690px;
  margin: 33px auto 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.intro {
  margin-left: auto;
  margin-right: auto;
  font-size: 28px;
  color: #4D4D4D;
  text-align: center;
}

.loan-title {
  line-height: 42px;
  padding-top: 38px;
  font-size: 30px;
  color: #1C0A1B;
  text-align: center;
}

.deadline-title {
  line-height: 42px;
  margin-top: 24px;
  margin-left: 26px;
  font-size: 30px;
  color: #1C0A1B;
}

.time {
  width: 222px;
  height: 108px;
  line-height: 108px;
  margin-top: 20px;
  margin-left: 26px;
  font-size: 30px;
  color: #E92326;
  text-align: center;
  background-image: url(../assets/img/can-loan-bg-active.png);
  background-size: cover;
}

.loan-amount {
  width: 750px;
  height: 327.56px;
  padding-top: 106px;
  margin-top: 9px;
  box-sizing: border-box;
  background: url(../assets/img/loan-status6-top-bg.png) no-repeat;
  background-size: cover;
  font-size: 48px;
  color: #FFF;
  text-align: center;
}

.loan-status {
  min-height: 100vh;
  background-color: #E6E6E6;
}

.loan-status1 {
  .desc {
    height: 589px;
    background-image: url(../assets/img/loan-status1-bg.png);
  }

  .intro {
    padding-top: 275px;
  }
}

.loan-status3 {
  .desc {
    height: 708px;
    background-image: url(../assets/img/loan-status3-bg.png);
  }

  .intro {
    padding-top: 210px;
  }

  .info {
    margin-top: 40px;

    p {
      line-height: 40px;
      font-size: 28px;
      text-align: center;

      &:nth-child(odd) {
        margin-top: 2px;
        color: #4D4D4D;
      }

      &:nth-child(even) {
        margin-top: 40px;
        color: #EF3D53;
      }
    }
  }
}

.loan-status4 {
  .desc {
    height: 606px;
    background-image: url(../assets/img/loan-status4-bg.png);
  }

  .intro {
    padding-top: 283px;
  }

  .detail {
    width: 536px;
    line-height: 40px;
    margin: 40px auto 0;
    font-size: 24px;
    color: #4D4D4D;
    text-align: center;
  }
}

.loan-status5 {
  .repayment-content {
    width: 696px;
    height: 515px;
    margin: 24px auto 0;
    background: url(../assets/img/loan-status5-bg.png) no-repeat;
    background-size: cover;
  }

  .p1 {
    line-height: 50px;
    padding-top: 136px;
    font-size: 36px;
    color: #1F1F1E;
    text-align: center;
  }

  .p2 {
    margin-top: 10px;
    font-size: 24px;
    color: #1F1F1E;
    text-align: center;
  }

  .overdue {
    margin-top: 10px;
    font-size: 26px;
    color: #333;
    text-align: center;
  }

  .repayment-sort {
    display: flex;
    align-items: center;
    position: relative;
    height: 120px;
    margin-top: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4px;
      height: 100%;
      transform: translateX(-50%) translateY(-50%);
      background-color: @theme-color;
    }

    & > div {
      flex: 1;

      p {
        line-height: 37px;
        font-size: 26px;
        font-weight: 600;
        color: #333;
        text-align: center;

        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }

  .detail {
    .submit-btn;
    width: 690px;
    margin-top: 24px;
  }

  .full-repayment-btn {
    .submit-btn;
    width: 690px;
    margin-top: 21px;
    background-color: #242A37;
  }

  .installment-repayment-btn {
    .submit-btn;
    width: 690px;
    margin-top: 15px;
  }
}

.loan-status6 {
  overflow: auto;

  .loan-amount-sort {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-left: 26px;
    padding-right: 26px;
    margin-top: 29px;

    & > div {
      width: 222px;
      height: 108px;
      line-height: 108px;
      font-size: 30px;
      color: #1C0A1B;
      text-align: center;
      background-image: url(../assets/img/no-loan-bg.png);
      background-size: cover;

      &:not(:nth-child(3n+1)) {
        margin-left: 16px;
      }

      &:nth-child(n+4) {
        margin-top: 20px;
      }

      &.active {
        background-image: url(../assets/img/can-loan-bg-active.png);
        color: #E92326;
      }
    }
  }

  .info-block {
    display: flex;
    margin: 24px 30px 0;

    & > div {
      height: 447px;
      padding-top: 86px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-size: cover;

      &:first-child {
        width: 340px;
        background-image: url(../assets/img/loan-bg.png);
      }

      &:last-child {
        width: 341px;
        margin-left: 9px;
        background-image: url(../assets/img/rate-bg.png);
      }

      p {
        line-height: 33px;
        font-size: 24px;
        color: #1C0A1B;
        text-align: center;

        &:last-child {
          margin-top: 24px;
        }
      }
    }
  }

  .submit {
    .submit-btn;
    width: 690px;
    margin-top: 100px;
    margin-bottom: 45px;
  }

  .no-loan-dialog {
    background-color: transparent;
  }

  .no-loan-dialog-content {
    width: 693px;
    height: 619px;
    margin-left: auto;
    margin-right: auto;
    background: url(../assets/img/no-loan-dialog-bg.png) no-repeat;
    background-size: cover;
  }

  .rise-limit-title, .tip-title {
    font-size: 30px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }

  .rise-limit-title {
    padding-top: 221px;
  }

  .tip-title {
    margin-top: 52px;
  }

  .tip {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 23px;
    font-size: 26px;
    color: #999;
    text-align: center;
  }

  .confirm-btn {
    .submit-btn;
    width: 693px;
    margin-top: 24px;
    margin-bottom: 84px;
    background-color: #E92326;
  }
}

.loan-status8, .loan-status9 {
  .desc {
    height: 541px;
    background-image: url(../assets/img/loan-status9-bg.png);
  }

  .intro {
    padding-top: 251px;
  }

  .detail {
    width: 536px;
    line-height: 40px;
    margin: 40px auto 0;
    font-size: 24px;
    color: #000;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    width: 690px;
    margin-top: 77px;
  }

  .cancel-loan-dialog {
    background-color: transparent;
  }

  .cancel-loan-dialog-content {
    width: 693px;
    height: 571px;
    margin-left: auto;
    margin-right: auto;
    background: url(../assets/img/loan-status7-bg.png) no-repeat;
    background-size: cover;
  }

  .cancel-loan-dialog-title, .tip {
    color: #000;
    text-align: center;
  }

  .cancel-loan-dialog-title {
    padding-top: 260px;
    font-size: 37px;
  }

  .tip {
    margin: 42px 30px 0;
    font-size: 24px;
  }

  .confirm-btn {
    .submit-btn;
    width: 693px;
    margin-top: 24px;
    margin-bottom: 48px;
    background-color: #E92326;
  }
}

.loan-status12 {
  .withdraw-btn {
    .submit-btn;
    width: 690px;
    margin-top: 300px;
    margin-bottom: 98px;
  }
}

.fee-title {
  .dialog-title;
}

.fee-detail {
  margin: 32px 25px 81px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;

    &:nth-child(n+2) {
      margin-top: 6px;
    }

    p {
      font-size: 26px;
      color: #333;
    }
  }
}

.footer-btn {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 45px;

  button {
    flex: 1;
    height: 88px;
    line-height: 88px;
    font-size: 30px;
    color: #FFF;
    border-radius: 17px;
  }

  .cancel-btn {
    background-color: #D2D2D2;
  }

  .ok-btn {
    margin-left: 26px;
    background-color: @theme-color;
  }
}
</style>
